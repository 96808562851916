import React, { useState, useEffect } from 'react'

import _find from 'lodash/find'
import _orderBy from 'lodash/orderBy'
import {
  getUpsertDefault,
  useGlobalContext,
} from '../../context/GlobalContext'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import styled from 'styled-components'
import LoaderTransparent from '../common/LoaderTransparent'
import {
  getSimplePolicies,
  getSimplePolicy,
  insertSimplePolicy,
  updateSimplePolicy,
  signSimplePolicy,
  syncSimplePolicy,
  changePolicyStatus,
} from '../../service/policySimpleApiService'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import {
  focusOnFirstFieldValueNameWithError, generateSubmitData,
} from '../../utils/formUtility'
import PolicySimpleACLUpsert from './PolicySimpleACLUpsert'
import {
  generateInitialUpsertPolicySimpleFieldValues, getUpsertPolicySimpleFieldValues,
  getUpsertPolicySimpleFieldValuesValidated,
} from '../../context/UpsertPolicySimpleFieldValues'
import PolicySimpleDetailsUpsert from './PolicySimpleDetailsUpsert'
import toastrService from '../../service/toastrService'
import { UpsertButtonsWrapper, UpsertCloseWrapper } from '../stylesComponents/Tags'
import { getEntities } from '../../service/entityApiService'
import { deploySimplePolicy, simplePolicyHeartBeat } from '../../api/entityEtherApi'
import PolicySimpleClaimUpsert from './PolicySimpleClaimUpsert'
import DocumentsUpsert from '../common/DocumentsUpsert'
import DocumentEnum from '../../enums/DocumentEnum'
import PolicySimpleSharableLinkUpsert from './PolicySimpleSharableLinkUpsert'
import { fromCrypto, toCrypto } from '../../utils/formatUtility'
import PolicySimpleInsuredConfirmUpsert from './PolicySimpleInsuredConfirmUpsert'
import PolicyStatusWithValueMap from '../../maps/PolicyStatusWithValueMap'
import { cancelSimplePolicy, generateSigningHash } from '../../api/policySimpleEtherApi'

const PolicyUpsertWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const PolicyUpsertFormWrapper = styled.div`
  position: relative;
`

const CloseIconWrapper = styled(CloseIcon)`
  cursor: pointer;
`

const ButtonsWrapper = styled.div`
  display: grid;
  grid-column-gap: 25px;
  grid-template-columns: 200px 200px;
  justify-content: flex-end;
  margin-bottom: 50px;
`

const PolicyUpsert = () => {
  const {
    upsertPolicySimpleFieldValues, setUpsertPolicySimpleFieldValues, upsertPolicySimpleAclFieldValues, currentSetting,
  } = useGlobalContext()

  const {
    upsertPolicySimple, setUpsertPolicySimple, paginationPolicySimple, setPaginationPolicySimple,
  } = useGlobalContext()

  const [cellEntityOptions, setCellEntityOptions] = useState([])
  const [insuredEntityOptions, setInsuredEntityOptions] = useState([])
  const [cellEntities, setCellEntities] = useState([])
  const [segregatedAccountEntityOptions, setSegregatedAccountEntityOptions] = useState([])
  const [supportedTokensIdWithObjectMap, setSupportedTokensIdWithObjectMap] = useState({})
  const [policy, setPolicy] = useState(null)
  const [entityCellId, setEntityCellId] = useState(null)

  const init = async () => {
    const setSupportedTokensIdWithObjectMapClone = currentSetting.data.supportedTokens.reduce((acc, supportedToken) => {
      acc[supportedToken.id] = supportedToken
      return acc
    }, {})

    setSupportedTokensIdWithObjectMap(setSupportedTokensIdWithObjectMapClone)

    setUpsertPolicySimple({
      ...upsertPolicySimple,
      fetchRequestStatus: RequestStatusEnum.loading,
    })

    const paginationResponse = await getEntities({
      page: 0, rowsPerPage: 10000,
    })
    setCellEntityOptions(paginationResponse.data
      .filter(entity => entity.entityType === 'cell')
      .map(entity => {
        return {
          value: entity.id,
          label: entity.entityName,
        }
    }))
    setInsuredEntityOptions(paginationResponse.data
      .filter(entity => entity.systemAclRole === 'Insured')
      .map(entity => {
        return {
          value: entity.id,
          label: entity.entityName,
        }
      }))

    setCellEntities(paginationResponse.data)

    const segregatedAccountEntitiesResponse = await getEntities({
      page: 0, rowsPerPage: 100, filters: {
        entityType: 'cell',
      },
    })
    setSegregatedAccountEntityOptions(segregatedAccountEntitiesResponse.data.map(entity => {
      return {
        value: entity.id,
        label: entity.entityName,
      }
    }))

    let policy = null
    if (upsertPolicySimple.id) {
      policy = await getSimplePolicy(upsertPolicySimple.id)
      setPolicy(policy)
      if (policy && policy.premiums) {
        policy.premiums.forEach((premium, index) =>  {
          premium.amount = fromCrypto(premium.amount, setSupportedTokensIdWithObjectMapClone?.[policy.asset]?.decimals || 18)
          premium.paidSoFar = premium.paidSoFar ? fromCrypto(premium.paidSoFar, setSupportedTokensIdWithObjectMapClone?.[policy.asset]?.decimals || 18) : '0'
        })
      }
      const fieldValuesUpdated = generateInitialUpsertPolicySimpleFieldValues(policy, upsertPolicySimpleFieldValues, setSupportedTokensIdWithObjectMapClone?.[policy.asset]?.decimals || 18)

      setUpsertPolicySimpleFieldValues(fieldValuesUpdated)
    }

    setUpsertPolicySimple({
      ...upsertPolicySimple,
      data: policy,
      fetchRequestStatus: RequestStatusEnum.success,
    })
  }


  useEffect(() => {
    init()
  }, [])

  const onClose = () => {
    setUpsertPolicySimple(getUpsertDefault())
  }

  const onCancel = () => {
    setUpsertPolicySimpleFieldValues(getUpsertPolicySimpleFieldValues())
  }

  const setSubmitRequestStatus = requestStatus => {
    setUpsertPolicySimple({
      ...upsertPolicySimple,
      submitRequestStatus: requestStatus,
    })
  }

  const onSubmit = async () => {
    const { fieldValuesValidated, isValid } = getUpsertPolicySimpleFieldValuesValidated(upsertPolicySimpleFieldValues)

    if (!isValid) {
      focusOnFirstFieldValueNameWithError(fieldValuesValidated)
      setSubmitRequestStatus(RequestStatusEnum.error)
      setUpsertPolicySimpleFieldValues(fieldValuesValidated)
      return
    }

    setSubmitRequestStatus(RequestStatusEnum.loading)

    const submitData = generateSubmitData(upsertPolicySimpleFieldValues)

    const cellEntity = getCellEntity(submitData.entityCellId)
    if (cellEntity) {
      submitData.asset = cellEntity.assetId
    }
    submitData.limit = submitData.limit ? toCrypto(submitData.limit, supportedTokensIdWithObjectMap[submitData.asset].decimals) : ''

    delete submitData.roleEntityId
    delete submitData.roleEntityType
    submitData.stakeholders = upsertPolicySimpleAclFieldValues.stakeholders.value || []
    const insured = _find(submitData.stakeholders, { role: 'Insured'});
    if (!insured && submitData.insuredPartyDisplayName) {
      submitData.stakeholders.push({
        role: 'Insured',
        displayName: submitData.insuredPartyDisplayName,
      })
    }

    const isInsert = !upsertPolicySimple.id

    let id

    if (submitData.premiums) {
      submitData.premiums.forEach((premium, index) => premium.amount = toCrypto(premium.amount, supportedTokensIdWithObjectMap[submitData.asset].decimals))
    }
    if (isInsert) {
      const policyResponse = await insertSimplePolicy(submitData)
      id = policyResponse.id
    }

    if (!isInsert) {
      id = upsertPolicySimple.id
      await updateSimplePolicy(submitData, id)
    }

    const upsertPolicyData = await getSimplePolicy(id)

    const paginationResponse = await getSimplePolicies({
      searchText: paginationPolicySimple.searchText,
      filters: paginationPolicySimple.filters,
      page: paginationPolicySimple.page,
      rowsPerPage: paginationPolicySimple.rowsPerPage,
    })

    setPaginationPolicySimple({
      ...paginationPolicySimple,
      count: paginationResponse.count,
      data: paginationResponse.data,
    })

    setUpsertPolicySimple({
      ...upsertPolicySimple,
      id: id,
      data: upsertPolicyData,
      submitRequestStatus: RequestStatusEnum.initial,
    })
  }

  const onSubmitWithErrorHandlingWrapper = async fnToExecute => {
    try {
      await fnToExecute()
    } catch (error) {
      console.error(error)
      toastrService.error(error.message)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }

  const isPolicyOnchain = () => {
    return upsertPolicySimple.data?.onChain
  }

  const isSignEnabled = () => {
    return upsertPolicySimple.data?.statusValue === PolicyStatusWithValueMap.POLICY_STATE_IN_APPROVAL
  }

  const isCancelEnabled = () => {
    return upsertPolicySimple.data?.statusValue === PolicyStatusWithValueMap.POLICY_STATE_ACTIVE
  }

  const isSendForApprovalEnabled = () => {
    if (!upsertPolicySimple?.data?.stakeholders) return false;
    if (upsertPolicySimple.data?.statusValue !== PolicyStatusWithValueMap.POLICY_STATE_DRAFT) return false;
    const { stakeholders } = upsertPolicySimple?.data;
    return stakeholders.every(s => s.signature === null || s.signature === undefined);
  }

  const isPolicyBlockchainDeployEnabled = () => {
    if (isPolicyOnchain()) return false;
    if (!upsertPolicySimple?.data?.stakeholders) return false;
    const { stakeholders } = upsertPolicySimple?.data;
    return stakeholders.find(s => s.signature === null || s.signature === undefined) === undefined;
  }

  const getCellEntity = (entityId) => {
    return cellEntities.filter(entity => entity.id === entityId)[0]
  }

  const getEmptyStakeholderData = () => ({
    roles: [],
    entityIds: [],
    signatures: [],
  });

  const addStakeholder = (acc, {roleId, entityId, signature}) => {
    const { roles, entityIds, signatures } = acc;
    return {
      roles: [...roles, roleId],
      entityIds: [...entityIds, entityId],
      signatures: [...signatures, signature],
    };
  };

  const isNotNullish = (x) => x != null;

  const getEmptyCommissions = () => ({
    commissionReceivers: [],
    commissionBasisPoints: [],
  });

  const addCommissionReceiver = (acc, { entityId, commission }) => {
    if (!(entityId && isNotNullish(commission))) return acc;
    const { commissionReceivers, commissionBasisPoints } = acc;
    return {
      commissionReceivers: [...commissionReceivers, entityId],
      commissionBasisPoints: [...commissionBasisPoints, commission],
    };
  };

  const refreshPolicyAndPagination = async() => {
    const upsertPolicyData = await getSimplePolicy(upsertPolicySimple.id)
    const paginationResponse = await getSimplePolicies({
      searchText: paginationPolicySimple.searchText, filters: paginationPolicySimple.filters,
      page: paginationPolicySimple.page, rowsPerPage: paginationPolicySimple.rowsPerPage,
    })
    setPaginationPolicySimple({
      ...paginationPolicySimple,
      count: paginationResponse.count,
      data: paginationResponse.data,
    })
    setUpsertPolicySimple({
      ...upsertPolicySimple,
      data: upsertPolicyData,
      submitRequestStatus: RequestStatusEnum.initial,
    })
  }

  const onDeployToBlockchain = async () => {
    if (!isPolicyBlockchainDeployEnabled()) {
      toastrService.error('Save policy as Draft first!')
      return
    }

    console.log({ upsertPolicySimple })
    const { stakeholders } = upsertPolicySimple.data

    const cellEntity = upsertPolicySimple.data.entityCellObject
    if (!cellEntity) {
      toastrService.error('Cell Entity is missing')
      return
    }
    console.log({ cellEntity })
    const asset = cellEntity.assetId

    const insuredParty = _find(stakeholders, { role: 'Insured'})
    if (!insuredParty || !insuredParty.entityId) {
      toastrService.error('Insured Party not confirmed yet!')
      return
    }

    const sortedStakeholders = _orderBy(stakeholders, ['signedBy'], ['asc']);
    const stakeholdersData = sortedStakeholders.reduce(addStakeholder, getEmptyStakeholderData());
    const { commissionReceivers, commissionBasisPoints } = stakeholders
      .filter(s => s.role !== 'Insured')
      .reduce(addCommissionReceiver, getEmptyCommissions());


    setSubmitRequestStatus(RequestStatusEnum.loading)

    await deploySimplePolicy({
      entityId: cellEntity.id,
      id: upsertPolicySimple.data.id,
      asset,
      startDate: upsertPolicySimple.data.startDate,
      maturationDate: upsertPolicySimple.data.maturationDate,
      limit: upsertPolicySimple.data.limit,
      state: upsertPolicySimple.data.statusValue,
      premiums: upsertPolicySimple.data.premiums,
      stakeholdersData,
      commissionReceivers,
      commissionBasisPoints,
      offchainDataHash: upsertPolicySimple.data.offchainDataHash,
    })

    await refreshPolicyAndPagination()
  }

  const onHeartBeat = async () => {
    try {
      setSubmitRequestStatus(RequestStatusEnum.loading)
      await simplePolicyHeartBeat({
        id: upsertPolicySimple.data.id,
      })
      await syncSimplePolicy(upsertPolicySimple.data.id)

      const upsertPolicyData = await getSimplePolicy(upsertPolicySimple.id)
      const paginationResponse = await getSimplePolicies({
        searchText: paginationPolicySimple.searchText, filters: paginationPolicySimple.filters,
        page: paginationPolicySimple.page, rowsPerPage: paginationPolicySimple.rowsPerPage,
      })
      setPaginationPolicySimple({
        ...paginationPolicySimple,
        count: paginationResponse.count,
        data: paginationResponse.data,
      })
      setUpsertPolicySimple({
        ...upsertPolicySimple,
        data: upsertPolicyData,
        submitRequestStatus: RequestStatusEnum.initial,
      })
    } catch (e) {
      toastrService.error(e)
      console.log(e)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }

  const onSendPolicyForApproval = async () => {
    if (!isSendForApprovalEnabled()) {
      toastrService.error('Save policy as Draft first!')
      return
    }
    console.log({ upsertPolicySimple })
    await changePolicyStatus(upsertPolicySimple.data.id, PolicyStatusWithValueMap.POLICY_STATE_IN_APPROVAL);

    await refreshPolicyAndPagination()
  }

  const onSignPolicy = async () => {
    if (!isSignEnabled()) {
      toastrService.error('Send policy for approval first!')
      return
    }

    console.log({ upsertPolicySimple })
    const signingHash = await generateSigningHash({
      id: upsertPolicySimple.data.id,
      startDate: upsertPolicySimple.data.startDate,
      maturationDate: upsertPolicySimple.data.maturationDate,
      asset: upsertPolicySimple.data.asset,
      limit: upsertPolicySimple.data.limit,
      offchainDataHash: upsertPolicySimple.data.offchainDataHash
    })

    console.log({
      fe: signingHash,
      be:upsertPolicySimple.data.signingHash,
    })

    await signSimplePolicy(upsertPolicySimple.data.id, upsertPolicySimple.data.signingHash)

    await refreshPolicyAndPagination()
  }

  const onCancelPolicy = async () => {
    setSubmitRequestStatus(RequestStatusEnum.loading)

    if (!isCancelEnabled()) {
      toastrService.error('Policy needs to be active!')
      return
    }

    await cancelSimplePolicy(upsertPolicySimple.data.id)

    // Refresh after 6 seconds to wait for backend event scraping to update policy status
    setTimeout(async () => await refreshPolicyAndPagination(), 6000)
  }

  const isLoading = () => {
    return [upsertPolicySimple.fetchRequestStatus, upsertPolicySimple.submitRequestStatus].includes(RequestStatusEnum.loading)
  }

  const getDeployButtonTooltipMessage = () => {
    return 'Deploy to Blockchain'
  }

  const getHeartBeatButtonTooltipMessage = () => {
    return 'Send Heart Beat to Blockchain'
  }

  const getSignButtonTooltipMessage = () => {
    return 'Sign policy'
  }

  const getSendForApprovalButtonTooltipMessage = () => {
    return 'Send policy for stakeholders approval'
  }

  return (
    <PolicyUpsertWrapper>

      <PolicyUpsertFormWrapper>
        <LoaderTransparent active={isLoading()}>
          <UpsertCloseWrapper>
            <CloseIconWrapper onClick={onClose}/>
          </UpsertCloseWrapper>

          <UpsertButtonsWrapper>

            <Tooltip title='Cancel Policy'>
              <div>
                <Button disabled={!isCancelEnabled()}
                        variant="outlined" size="medium"
                        color="primary" onClick={() => onSubmitWithErrorHandlingWrapper(onCancelPolicy)}>
                  Cancel Policy
                </Button>
              </div>
            </Tooltip>

            <Tooltip title={getSendForApprovalButtonTooltipMessage()}>
              <div>
                <Button disabled={!isSendForApprovalEnabled()}
                        variant="outlined" size="medium"
                        color="primary" onClick={() => onSubmitWithErrorHandlingWrapper(onSendPolicyForApproval)}>
                  Send for approval
                </Button>
              </div>
            </Tooltip>

            <Tooltip title={getSignButtonTooltipMessage()}>
              <div>
                <Button disabled={!isSignEnabled()}
                        variant="outlined" size="medium"
                        color="primary" onClick={() => onSubmitWithErrorHandlingWrapper(onSignPolicy)}>
                  Sign Policy
                </Button>
              </div>
            </Tooltip>

            <Tooltip title={getDeployButtonTooltipMessage()}>
              <div>
                <Button disabled={!isPolicyBlockchainDeployEnabled()}
                        variant="outlined" size="medium"
                        color="primary" onClick={() => onSubmitWithErrorHandlingWrapper(onDeployToBlockchain)}>
                  Deploy
                </Button>
              </div>
            </Tooltip>

            <Tooltip title={getHeartBeatButtonTooltipMessage()}>
              <div>
                <Button
                  disabled={!isPolicyOnchain()}
                  variant="outlined" size="medium"
                  color="primary" onClick={() => onSubmitWithErrorHandlingWrapper(onHeartBeat)}>
                  Heart Beat
                </Button>
              </div>
            </Tooltip>
          </UpsertButtonsWrapper>

          <PolicySimpleDetailsUpsert cellEntityOptions={cellEntityOptions}
                                     segregatedAccountEntityOptions={segregatedAccountEntityOptions}
                                     tokensIdMap={supportedTokensIdWithObjectMap}
                                     policy={policy}
                                     setEntityCellId={setEntityCellId}
          />

          <br/>

          <br/>

          <PolicySimpleACLUpsert entityCellId={entityCellId} />

          <br/>

          <ButtonsWrapper>
            <Button variant="outlined" size="medium" color="secondary" onClick={onCancel}>
              Cancel
            </Button>

            <Button variant="outlined" size="medium" color="primary"
                    onClick={() => onSubmitWithErrorHandlingWrapper(onSubmit)}>
              Save
            </Button>
          </ButtonsWrapper>

        </LoaderTransparent>
      </PolicyUpsertFormWrapper>

      {upsertPolicySimple.data?.id &&
        (<PolicySimpleInsuredConfirmUpsert
          insuredEntityOptions={insuredEntityOptions}
          policy={policy}
          setPolicy={setPolicy}
        />)}

      {upsertPolicySimple.data?.id && (<PolicySimpleSharableLinkUpsert/>)}

      {upsertPolicySimple.data?.id && (
        <DocumentsUpsert referenceId={upsertPolicySimple.data.id}
                               referenceType={DocumentEnum.referenceTypes.policy}
                               relatedToOptions={[]}
                               documentTypeOptions={DocumentEnum.policyDocumentTypes}/>
      )}

      {upsertPolicySimple.data?.id && (<PolicySimpleClaimUpsert
        supportedTokensIdWithObjectMap={supportedTokensIdWithObjectMap} />)}

      <br/>
      <br/>

    </PolicyUpsertWrapper>
  )
}

export default PolicyUpsert

