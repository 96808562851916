import JobTypeEnum from '../enums/JobTypeEnum'
import JobStatusEnum from '../enums/JobStatusEnum'
import CellStatusEnum from '../enums/CellStatusEnum'
import constants from './constants'

const noneOptions = { value: '', label: 'None' }

export const jobTypeOptions = Object.keys(JobTypeEnum).map(key => {
  return { value: key, label: JobTypeEnum[key] }
})

export const jobTypeOptionsWithNone = [noneOptions, ...jobTypeOptions]

export const jobStatusOptions = Object.keys(JobStatusEnum).map(key => {
  return { value: key, label: JobStatusEnum[key] }
})

export const jobStatusOptionsWithNone = [noneOptions, ...jobStatusOptions]


export const getTypeOfRiskOptions = (currentSetting, shouldPrependNoneOption) => {
  const options = currentSetting.data && currentSetting.data.typeOfRisk.map(typeOfRisk => ({
    value: typeOfRisk,
    label: typeOfRisk.toUpperCase(),
  })) || []

  shouldPrependNoneOption && options.unshift({ value: '', label: 'None' })
  return options
}

export const getPremiumScheduleTypeOptions = () => ([
  { value: 'annually', label: 'Annually' },
  { value: 'semi-annually', label: 'Semi-annually' },
  { value: 'Quarterly', label: 'Quarterly' },
  { value: 'Monthly', label: 'Monthly' },
  { value: 'custom', label: 'Custom' },
  { value: 'none', label: 'None' },
])


export const getYesNowOptions = () => {
  return [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ]
}

export const getCellStatusOptions = () => {
  return Object.keys(CellStatusEnum).map(cellStatus => ({
    value: CellStatusEnum[cellStatus],
    label: CellStatusEnum[cellStatus],
  }))
}

export const getAssetAddressOptions = (currentSetting) => {
  return [
    { value: constants.ADDRESS_ZERO, label: 'None' },
    ...(currentSetting.data && currentSetting.data.supportedTokens.map(supportedToken => ({
      value: supportedToken.address,
      label: supportedToken.name,
    })) || []),
  ]
}

export const getAssetOptions = (currentSetting) => {
  return [
    { value: '', label: 'None' },
    ...(currentSetting.data && currentSetting.data.supportedTokens.map(supportedToken => ({
      value: supportedToken.id,
      label: supportedToken.name,
    })) || []),
  ]
}

export const getRiskLevelOptions = () => {
  return [
    { value: 'conservative', label: 'Conservative' },
    { value: 'moderately_conservative', label: 'Moderately conservative' },
    { value: 'moderate', label: 'Moderate' },
    { value: 'moderately_aggressive', label: 'Moderately aggressive' },
    { value: 'aggressive', label: 'Aggressive' },
    { value: '', label: 'Clear' },
  ]
}

export const getEntityOrganizationTypeOptions = () => {
  return [
    { value: 'NATURAL_PERSON', label: 'Natural Person' },
    { value: 'CORPORATE_ENTITY', label: 'Corporate Entity' },
  ]
}
