import React, { useEffect } from 'react'
import { ethers } from 'ethers'
import { useGlobalContext } from '../../context/GlobalContext'
import RequestStatusEnum from '../../enums/RequestStatusEnum'
import styled from 'styled-components'
import LoaderTransparent from '../common/LoaderTransparent'
import entityApiService from '../../service/entityApiService'
import Button from '@material-ui/core/Button'
import {
  focusOnFirstFieldValueNameWithError,
  generateSubmitData,
  mapInitialFieldValuesWithObjectValues,
} from '../../utils/formUtility'

import toastrService from '../../service/toastrService'
import { SelectFieldValue200pxWrapper } from '../stylesComponents/UpsertCommon'
import {
  getUpsertEntityOffchainFieldValues,
  getUpsertEntityOffchainFieldValuesValidated,
} from '../../context/UpsertEntityOffchainFieldValues'
import FieldValueBase from '../fieldValue/FieldValueBase'
import FieldValueSelect from '../fieldValue/FieldValueSelect'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {
  BoxWrapper,
  ButtonsWrapper,
  Flex,
  PositionRelative,
  Width800Wrapper,
} from '../stylesComponents/Tags'
import {
  getCellStatusOptions,
  getEntityOrganizationTypeOptions,
  getRiskLevelOptions,
} from '../../utils/optionsUtility'
import AclRoleEnum from '../../enums/AclRoleEnum'
import FieldValueDate from '../fieldValue/FieldValueDate'
import FieldValueMultiSelect from '../fieldValue/FieldValueMultiSelect'
import { ButtonWrapper } from '../stylesComponents/Buttons'
import { FieldValueCountrySelect } from '../fieldValue/FieldValueCountrySelect'

const FormControlLabelWrapper = styled(FormControlLabel)`
  margin: 0;
`

const StyledFlex = styled(Flex)`
    gap: 30px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const ForecastFlex = styled(Flex)`
    gap: 30px;
    justify-content: start;
    align-items: center;
    width: 100%;
`;

const OuterFlex = styled(Flex)`
  border: 1px solid #d0d0d0;
    border-radius: 8px;
    flex-direction: column;
    gap: 20px;
    padding: 24px;
    align-items: start;
`;

const Label = styled.p`
  font-size: 16px;
    font-weight: 400;
    line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
`;

const Cell = styled.div`
    width: 40%;
`;

export default () => {
  const {
    upsertEntity, setUpsertEntity, paginationEntity, setPaginationEntity,
    upsertEntityOffchainFieldValues, setUpsertEntityOffchainFieldValues, currentSetting,
    upsertEntityOffchain, setUpsertEntityOffchain,
  } = useGlobalContext()

  const isEntitySegregatedAccount = upsertEntity?.data?.systemAclRole === AclRoleEnum.roleSeggregatedAccount || upsertEntityOffchainFieldValues.entityType?.value === 'cell'


  const entityTypesOptions = [{ value: '', label: 'None' }, ...currentSetting.data.entityTypes]
  const businessTypesOptions = currentSetting.data.businessTypes

  const init = async () => {
    if (upsertEntity.id) {
      let entityDynamoDbWithDefault = {
        ...upsertEntity.data,
      }
      const fieldValuesUpdated = mapInitialFieldValuesWithObjectValues(entityDynamoDbWithDefault, upsertEntityOffchainFieldValues)
      setUpsertEntityOffchainFieldValues(fieldValuesUpdated)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const onFieldUpdate = e => {
    const fieldValuesClone = { ...upsertEntityOffchainFieldValues }
    fieldValuesClone[e.target.name] = {
      ...fieldValuesClone[e.target.name],
      value: e.target.value,
    }

    const entityIsSegregatedAccount = isEntitySegregatedAccount || fieldValuesClone.entityType?.value === 'cell'
    const { fieldValuesValidated } = getUpsertEntityOffchainFieldValuesValidated(fieldValuesClone, entityIsSegregatedAccount)
    setUpsertEntityOffchainFieldValues(fieldValuesValidated)
  }

  const onSwitchIsHidden = e => {
    onFieldUpdate({
      target: {
        name: e.target.name,
        value: !upsertEntityOffchainFieldValues.isHidden.value,
      },
    })
  }

  const onCancel = () => {
    setUpsertEntityOffchainFieldValues(getUpsertEntityOffchainFieldValues())
  }

  const setSubmitRequestStatus = requestStatus => {
    setUpsertEntityOffchain({
      ...upsertEntity,
      submitRequestStatus: requestStatus,
    })
  }

  const onSubmit = async () => {
    const {
      fieldValuesValidated,
      isValid,
    } = getUpsertEntityOffchainFieldValuesValidated(upsertEntityOffchainFieldValues, isEntitySegregatedAccount)

    if (!isValid) {
      focusOnFirstFieldValueNameWithError(fieldValuesValidated)
      toastrService.error('Please fill all required fields')
      setSubmitRequestStatus(RequestStatusEnum.error)
      setUpsertEntityOffchainFieldValues(fieldValuesValidated)
      return
    }

    setSubmitRequestStatus(RequestStatusEnum.loading)

    const submitData = generateSubmitData(upsertEntityOffchainFieldValues)

    const arrToHex = a => 
      a.map(n => `${n.toString(16)}`.padStart(2, '0')).join('')

    let entityId = upsertEntity.id
    if (!entityId) {
      const randomBytes = ethers.utils.randomBytes(20)
      const randomString = arrToHex(Array.from(randomBytes))
      
      // 12 bytes hex of 'ENTITY' string
      const typePrefix = '0x454e54495459000000000000';
      
      entityId = typePrefix + randomString
    }

    await entityApiService.upsertEntity({
      ...submitData,
      id: entityId,
    })

    const paginationResponse = await entityApiService.getEntities({
      searchText: paginationEntity.searchText, page: paginationEntity.page, rowsPerPage: paginationEntity.rowsPerPage,
    })

    setPaginationEntity({
      ...paginationEntity,
      count: paginationResponse.count,
      data: paginationResponse.data,
    })

    setUpsertEntity({
      ...upsertEntity,
      id: entityId,
      data: {
        ...upsertEntity.data,
        ...submitData,
      },
      submitRequestStatus: RequestStatusEnum.initial,
    })

    setSubmitRequestStatus(RequestStatusEnum.success)
  }

  const onSubmitWithErrorHandling = async () => {
    try {
      await onSubmit()
    } catch (error) {
      console.error(error)
      toastrService.error(error)
      setSubmitRequestStatus(RequestStatusEnum.error)
    }
  }

  const isLoading = () => {
    return upsertEntityOffchain.submitRequestStatus === RequestStatusEnum.loading
  }

  const onUpdateCellmetrics = async () => {
    try{
      await entityApiService.updateCellMetrics(upsertEntity.id)
      toastrService.success(`Updated cell metrics for ${upsertEntityOffchainFieldValues.entityName.value}`)
    }
    catch (error) {
      console.error(error)
      toastrService.error(error)
    }
  }

  const onAddForecastedPeriodicPerformance = () => {

    const fieldValuesClone = {
      ...upsertEntityOffchainFieldValues,
      forecastedPeriodicPerformance: {
        ...upsertEntityOffchainFieldValues.forecastedPeriodicPerformance,
        value: [
          ...upsertEntityOffchainFieldValues.forecastedPeriodicPerformance.value,
          { dateOn: '', value: '', adjustment: '', cumulativeAdjustment: '' },
        ],
      },
    }

    const { fieldValuesValidated } = getUpsertEntityOffchainFieldValuesValidated(fieldValuesClone)

    setUpsertEntityOffchainFieldValues(fieldValuesValidated)
  }

  const onUpdateForecastedPeriodicPerformance = e => {

    const eventNameArray = e.target.name.split('-')
    const forecastedIndex = eventNameArray[0]
    const forecastedName = eventNameArray[1]

    const forecastedPeriodicPerformanceValueClone = [...upsertEntityOffchainFieldValues.forecastedPeriodicPerformance.value]
    forecastedPeriodicPerformanceValueClone[forecastedIndex][forecastedName] = e.target.value

    const fieldValuesClone = {
      ...upsertEntityOffchainFieldValues,
      forecastedPeriodicPerformance: {
        ...upsertEntityOffchainFieldValues.forecastedPeriodicPerformance,
        value: forecastedPeriodicPerformanceValueClone,
      },
    }

    const { fieldValuesValidated } = getUpsertEntityOffchainFieldValuesValidated(fieldValuesClone)
    setUpsertEntityOffchainFieldValues(fieldValuesValidated)
  }

  const onDeleteForecastedPeriodicPerformance = (forecastedIndex) => {
    const forecastedPeriodicPerformanceValueClone = [...upsertEntityOffchainFieldValues.forecastedPeriodicPerformance.value]
    forecastedPeriodicPerformanceValueClone.splice(forecastedIndex, 1)

    const fieldValuesClone = {
      ...upsertEntityOffchainFieldValues,
      forecastedPeriodicPerformance: {
        ...upsertEntityOffchainFieldValues.forecastedPeriodicPerformance,
        value: forecastedPeriodicPerformanceValueClone,
      },
    }
    const { fieldValuesValidated } = getUpsertEntityOffchainFieldValuesValidated(fieldValuesClone)

    setUpsertEntityOffchainFieldValues(fieldValuesValidated)
  }

  // console.log(upsertEntityOffchainFieldValues);

  return (
    <PositionRelative>
      <LoaderTransparent active={isLoading()}>
        <BoxWrapper>
          <h4>Offchain Data</h4>

          <FieldValueBase
            value={upsertEntityOffchainFieldValues.entityName.value}
            name="entityName"
            label="Entity Name"
            withError
            error={upsertEntityOffchainFieldValues.entityName.error}
            onFieldUpdate={onFieldUpdate}
          />

          <FormControlLabelWrapper
            control={(
              <Switch color="primary" onChange={onSwitchIsHidden} name="isHidden"
                      checked={!!upsertEntityOffchainFieldValues.isHidden.value}/>
            )}
            label="Is Hidden"
            labelPlacement="start"
          />

          <FieldValueBase
            value={upsertEntityOffchainFieldValues.description.value}
            name="description"
            label="Description"
            multiline
            minRows={2}
            maxRows={4}
            withError
            error={upsertEntityOffchainFieldValues.description.error}
            onFieldUpdate={onFieldUpdate}
          />

          <SelectFieldValue200pxWrapper>
            <FieldValueSelect
              value={upsertEntityOffchainFieldValues.entityType.value}
              options={entityTypesOptions}
              name="entityType"
              label="Entity Type"
              onFieldUpdate={onFieldUpdate}
            />
          </SelectFieldValue200pxWrapper>

          <FieldValueBase
            value={upsertEntityOffchainFieldValues.addressPrimary.value}
            name="addressPrimary"
            label="Address Primary"
            withError
            error={upsertEntityOffchainFieldValues.addressPrimary.error}
            onFieldUpdate={onFieldUpdate}
          />

          <FieldValueBase
            value={upsertEntityOffchainFieldValues.country.value}
            name="country"
            label="Country"
            withError
            error={upsertEntityOffchainFieldValues.country.error}
            onFieldUpdate={onFieldUpdate}
          />

          <FieldValueBase
            value={upsertEntityOffchainFieldValues.city.value}
            name="city"
            label="City"
            withError
            error={upsertEntityOffchainFieldValues.city.error}
            onFieldUpdate={onFieldUpdate}
          />

          <FieldValueBase
            value={upsertEntityOffchainFieldValues.zipCode.value}
            name="zipCode"
            label="ZipCode"
            withError
            error={upsertEntityOffchainFieldValues.zipCode.error}
            onFieldUpdate={onFieldUpdate}
          />

          <FieldValueBase
            value={upsertEntityOffchainFieldValues.contactName.value}
            name="contactName"
            label="Contact Name"
            withError
            error={upsertEntityOffchainFieldValues.contactName.error}
            onFieldUpdate={onFieldUpdate}
          />

          <FieldValueBase
            value={upsertEntityOffchainFieldValues.contactAddress.value}
            name="contactAddress"
            label="Contact Address"
            withError
            error={upsertEntityOffchainFieldValues.contactAddress.error}
            onFieldUpdate={onFieldUpdate}
          />
          <FieldValueBase
            value={upsertEntityOffchainFieldValues.contactEmail.value}
            name="contactEmail"
            label="Contact E-mail"
            withError
            error={upsertEntityOffchainFieldValues.contactEmail.error}
            onFieldUpdate={onFieldUpdate}
          />
          <SelectFieldValue200pxWrapper>
            <FieldValueSelect
              value={upsertEntityOffchainFieldValues.organizationType.value}
              options={getEntityOrganizationTypeOptions()}
              name="organizationType"
              label="Organization Type"
              withError
              error={upsertEntityOffchainFieldValues.organizationType.error}
              onFieldUpdate={onFieldUpdate}
            />
          </SelectFieldValue200pxWrapper>
          <FieldValueCountrySelect
            value={upsertEntityOffchainFieldValues.registrationJurisdiction.value}
            name="registrationJurisdiction"
            label="Jurisdiction of Registration"
            onFieldUpdate={onFieldUpdate}
          />
          <FieldValueBase
            value={upsertEntityOffchainFieldValues.registeredCompanyNumber.value}
            name="registeredCompanyNumber"
            label="Legal Registration Number"
            withError
            error={upsertEntityOffchainFieldValues.registeredCompanyNumber.error}
            onFieldUpdate={onFieldUpdate}
          />
          {isEntitySegregatedAccount && (
            <>
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.sponsor.value}
                name="sponsor"
                label="Sponsor"
                withError
                error={upsertEntityOffchainFieldValues.sponsor.error}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueMultiSelect
                multiple
                value={upsertEntityOffchainFieldValues.businessTypes.value}
                options={businessTypesOptions}
                name="businessTypes"
                label="Business Types"
                withError
                error={upsertEntityOffchainFieldValues.businessTypes.error}
                onFieldUpdate={onFieldUpdate}
              />

              <SelectFieldValue200pxWrapper>
                <FieldValueSelect
                  value={upsertEntityOffchainFieldValues.cellStatus.value}
                  options={getCellStatusOptions()}
                  name="cellStatus"
                  label="Cell Status"
                  withError
                  error={upsertEntityOffchainFieldValues.cellStatus.error}
                  onFieldUpdate={onFieldUpdate}
                />
              </SelectFieldValue200pxWrapper>

              <SelectFieldValue200pxWrapper>
                <FieldValueSelect
                  value={upsertEntityOffchainFieldValues.riskLevel.value}
                  options={getRiskLevelOptions()}
                  name="riskLevel"
                  label="Risk Level"
                  withError
                  error={upsertEntityOffchainFieldValues.riskLevel.error}
                  onFieldUpdate={onFieldUpdate}
                />
              </SelectFieldValue200pxWrapper>

              <FieldValueBase
                value={upsertEntityOffchainFieldValues.targetRoi.value}
                name="targetRoi"
                label="Target ROI"
                type="number"
                adornmentValue="%"
                withError
                error={upsertEntityOffchainFieldValues.targetRoi.error}
                onFieldUpdate={onFieldUpdate}
                disabled={upsertEntityOffchainFieldValues.targetRoiMin.value || upsertEntityOffchainFieldValues.targetRoiMax.value}
              />

              <FieldValueBase
                value={upsertEntityOffchainFieldValues.targetRoiMin.value}
                name="targetRoiMin"
                label="Minimum Target ROI"
                type="number"
                adornmentValue="%"
                withError
                error={upsertEntityOffchainFieldValues.targetRoiMin.error}
                onFieldUpdate={onFieldUpdate}
                disabled={upsertEntityOffchainFieldValues.targetRoi.value}
              />

              <FieldValueBase
                value={upsertEntityOffchainFieldValues.targetRoiMax.value}
                name="targetRoiMax"
                label="Maximum Target ROI"
                type="number"
                adornmentValue="%"
                withError
                error={upsertEntityOffchainFieldValues.targetRoiMax.error}
                onFieldUpdate={onFieldUpdate}
                disabled={upsertEntityOffchainFieldValues.targetRoi.value}
              />

              <FieldValueBase
                value={upsertEntityOffchainFieldValues.actualRoiAdjustment.value}
                name="actualRoiAdjustment"
                label="Actual ROI Adjustment"
                type="number"
                placeholder="e.g. 10000"
                withError
                error={upsertEntityOffchainFieldValues.actualRoiAdjustment.error}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                value={upsertEntityOffchainFieldValues.expectedPremiumAdjustment.value}
                name="expectedPremiumAdjustment"
                label="Expected Premium Adjustment"
                type="number"
                placeholder="e.g. 10000"
                withError
                error={upsertEntityOffchainFieldValues.expectedPremiumAdjustment.error}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                value={upsertEntityOffchainFieldValues.paidClaimsAdjustment.value}
                name="paidClaimsAdjustment"
                label="Paid Claims Adjustment"
                type="number"
                placeholder="e.g. 10000"
                withError
                error={upsertEntityOffchainFieldValues.paidClaimsAdjustment.error}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                value={upsertEntityOffchainFieldValues.paidPremiumAdjustment.value}
                name="paidPremiumAdjustment"
                label="Paid Premium Adjustment"
                type="number"
                placeholder="e.g. 10000"
                withError
                error={upsertEntityOffchainFieldValues.paidPremiumAdjustment.error}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                value={upsertEntityOffchainFieldValues.paidClaimsAdjustment.value}
                name="paidClaimsAdjustment"
                label="Paid Claims Adjustment"
                type="number"
                placeholder="e.g. 10000"
                withError
                error={upsertEntityOffchainFieldValues.paidClaimsAdjustment.error}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                value={upsertEntityOffchainFieldValues.expectedExpensesAdjustment.value}
                name="expectedExpensesAdjustment"
                label="Expected Expenses Adjustment"
                type="number"
                placeholder="e.g. 10000"
                withError
                error={upsertEntityOffchainFieldValues.expectedExpensesAdjustment.error}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                value={upsertEntityOffchainFieldValues.paidExpensesAdjustment.value}
                name="paidExpensesAdjustment"
                label="Paid Expenses Adjustment"
                type="number"
                placeholder="e.g. 10000"
                withError
                error={upsertEntityOffchainFieldValues.paidExpensesAdjustment.error}
                onFieldUpdate={onFieldUpdate}
              />

              <FieldValueBase
                value={upsertEntityOffchainFieldValues.targetCapitalization.value}
                name="targetCapitalization"
                label="Target Capitalization"
                type="number"
                placeholder="USD 500,000"
                adornmentValue="$"
                withError
                error={upsertEntityOffchainFieldValues.targetCapitalization.error}
                onFieldUpdate={onFieldUpdate}
              />
              <FieldValueSelect
                value={upsertEntityOffchainFieldValues.riskDiversification.value}
                options={getRiskLevelOptions()}
                name="riskDiversification"
                label="Risk Diversification "
                onFieldUpdate={onFieldUpdate}
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.collateralization.value}
                name="collateralization"
                label="Collateralization"
                type="number"
                placeholder="e.g. 50%"
                adornmentValue="%"
                withError
                error={upsertEntityOffchainFieldValues.collateralization.error}
                onFieldUpdate={onFieldUpdate}
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.aggregateLimit.value}
                name="aggregateLimit"
                label="Aggregate Limit"
                type="number"
                placeholder="e.g. USD 500,000"
                adornmentValue="$"
                error={upsertEntityOffchainFieldValues.aggregateLimit.error}
                withError
                onFieldUpdate={onFieldUpdate}
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.averageLossLimit.value}
                name="averageLossLimit"
                label="Average Loss Limit"
                type="number"
                placeholder="e.g. USD 500"
                adornmentValue="$"
                onFieldUpdate={onFieldUpdate}
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.expectedLossRatio.value}
                name="expectedLossRatio"
                label="Expected Loss Ratio"
                type="number"
                placeholder="e.g. 80%"
                adornmentValue="%"
                onFieldUpdate={onFieldUpdate}
                withError
                error={upsertEntityOffchainFieldValues.expectedLossRatio.error}
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.expectedCombinedRatio.value}
                name="expectedCombinedRatio"
                label="Expected Combined Ratio"
                type="number"
                placeholder="e.g. 90%"
                adornmentValue="%"
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.expectedCombinedRatio.error}
                withError
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.maximumPolicyLimit.value}
                name="maximumPolicyLimit"
                label="Maximum Policy Limit"
                type="number"
                placeholder="e.g. USDC 100,000"
                adornmentValue="$"
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.maximumPolicyLimit.error}
                withError
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.ultimateClaims.value}
                name="ultimateClaims"
                label="Ultimate Claims"
                type="number"
                placeholder="e.g. 100,000"
                adornmentValue="$"
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.ultimateClaims.error}
                withError
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.ultimateClaimsAdjustment.value}
                name="ultimateClaimsAdjustment"
                label="Ultimate Claims Adjustment"
                type="number"
                placeholder="e.g. 100,000"
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.ultimateClaimsAdjustment.error}
                withError
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.expectedClaims.value}
                name="expectedClaims"
                label="Expected claims"
                type="number"
                placeholder="e.g. 10,000"
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.expectedClaims.error}
                withError
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.historicalLossRatioAdjustment.value}
                name="historicalLossRatioAdjustment"
                label="Historical Loss Ratio Adjustment"
                type="number"
                placeholder="e.g. 10,000"
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.historicalLossRatioAdjustment.error}
                withError
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.expenseRatioAdjustment.value}
                name="expenseRatioAdjustment"
                label="Expense Ratio Adjustment"
                type="number"
                placeholder="e.g. 10,000"
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.expenseRatioAdjustment.error}
                withError
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.netProfitAdjustment.value}
                name="netProfitAdjustment"
                label="Net Profit Adjustment"
                type="number"
                placeholder="e.g. 10,000"
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.netProfitAdjustment.error}
                withError
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.navAdjustment.value}
                name="navAdjustment"
                label="NAV Adjustment"
                type="number"
                placeholder="e.g. 10,000"
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.navAdjustment.error}
                withError
              />

              <Width800Wrapper>
              <OuterFlex>

                <StyledFlex>
                  <div>
                    <Label>Forecasted Periodic Performance</Label>
                  </div>

                  <Button variant="outlined" size="medium" color="primary"
                          onClick={onAddForecastedPeriodicPerformance}>
                    Add
                  </Button>
                </StyledFlex>

                {upsertEntityOffchainFieldValues.forecastedPeriodicPerformance.value.map((performancePoint, performancePointIndex) => {
                  return (
                    <ForecastFlex key={performancePointIndex}>
                      <Cell>
                        <FieldValueDate
                          value={performancePoint.dateOn}
                          name={`${performancePointIndex}-dateOn`}
                          label="Forecasted On"
                          onFieldUpdate={onUpdateForecastedPeriodicPerformance}
                        />
                      </Cell>

                      <Cell>
                        <FieldValueBase
                          value={performancePoint.value}
                          name={`${performancePointIndex}-value`}
                          label="Forecasted Value"
                          type="number"
                          onFieldUpdate={onUpdateForecastedPeriodicPerformance}
                        />
                      </Cell>

                      <Cell>
                        <FieldValueBase
                          value={performancePoint.adjustment}
                          name={`${performancePointIndex}-adjustment`}
                          label="Adjustment"
                          type="number"
                          onFieldUpdate={onUpdateForecastedPeriodicPerformance}
                        />
                      </Cell>

                      <Cell>
                        <FieldValueBase
                          value={performancePoint.cumulativeAdjustment}
                          name={`${performancePointIndex}-cumulativeAdjustment`}
                          label="Cumulative Adjustment"
                          type="number"
                          onFieldUpdate={onUpdateForecastedPeriodicPerformance}
                        />
                      </Cell>

                      <Button variant="outlined" size="medium" color="secondary"
                              onClick={() => onDeleteForecastedPeriodicPerformance(performancePointIndex)}>
                        Delete
                      </Button>
                    </ForecastFlex>
                  )
                })}
              </OuterFlex>
              </Width800Wrapper>

              <FieldValueDate
                value={upsertEntityOffchainFieldValues.programLaunchDate.value}
                name="programLaunchDate"
                label="Program Launch Date"
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.programLaunchDate.error}
                withError
              />
              <FieldValueDate
                value={upsertEntityOffchainFieldValues.programEndDate.value}
                name="programEndDate"
                label="Program End Date"
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.programEndDate.error}
                withError
              />
              <FieldValueDate
                value={upsertEntityOffchainFieldValues.riskEarningEndDate.value}
                name="riskEarningEndDate"
                label="Risk/Earning End Date"
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.riskEarningEndDate.error}
                withError
              />
              <FieldValueDate
                value={upsertEntityOffchainFieldValues.reportingEndDate.value}
                name="reportingEndDate"
                label="Reporting End Date"
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.reportingEndDate.error}
                withError
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.background.value}
                name="background"
                label="Background"
                type="string"
                multiline
                rows={3}
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.background.error}
                withError
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.coverage.value}
                name="coverage"
                label="Coverage"
                type="string"
                multiline
                rows={3}
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.coverage.error}
                withError
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.opportunity.value}
                name="opportunity"
                label="Opportunity"
                type="string"
                multiline
                rows={3}
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.opportunity.error}
                withError
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.riskDescription.value}
                name="riskDescription"
                label="Risk Description"
                type="string"
                multiline
                rows={3}
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.riskDescription.error}
                withError
              />

              <FieldValueBase
                value={upsertEntityOffchainFieldValues.sponsorName.value}
                name="sponsorName"
                label="Sponsor Name"
                type="string"
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.sponsorName.error}
                withError
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.sponsorWebsite.value}
                name="sponsorWebsite"
                label="Sponsor Website"
                type="string"
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.sponsorWebsite.error}
                withError
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.underwriterName.value}
                name="underwriterName"
                label="Underwriter Name"
                type="string"
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.underwriterName.error}
                withError
              />
              <FieldValueBase
                value={upsertEntityOffchainFieldValues.underwriterWebsite.value}
                name="underwriterWebsite"
                label="Underwriter Website"
                type="string"
                onFieldUpdate={onFieldUpdate}
                error={upsertEntityOffchainFieldValues.underwriterWebsite.error}
                withError
              />

            </>
          )}

          {upsertEntity.id && isEntitySegregatedAccount && (
            <ButtonWrapper>
              <Button variant="outlined" size="medium" color="primary"
                      onClick={onUpdateCellmetrics}
              >
                Update cell metrics
              </Button>
            </ButtonWrapper>
          )}

          <ButtonsWrapper>
            <Button variant="outlined" size="medium" color="secondary" onClick={onCancel}>
              Cancel
            </Button>

            <Button variant="outlined" size="medium" color="primary" onClick={onSubmitWithErrorHandling}>
              Save
            </Button>
          </ButtonsWrapper>

        </BoxWrapper>
      </LoaderTransparent>
    </PositionRelative>
  )
}
